import React, { useState } from 'react';

import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { LatLng } from 'leaflet';

const PanningMap = () => {
  const [latDirection, setLatDirection] = useState(-1);
  const [lngDirection, setLngDirection] = useState(-1);
  const delta = 1;

  const panOptions = {
    animate: true,
    duration: 30,
    easeLinearity: 1
  };

  const getNewCenter = center => {
    const lat = center.lat + (delta * latDirection);
    const lng = center.lng + (delta * lngDirection);

    const edgeMargin = 0.95;
    if (lat >= (90 * edgeMargin) || lat <= (-90 * edgeMargin)) setLatDirection(latDirection * -1);
    if (lng >= (180 * edgeMargin) || lng <= (-180 * edgeMargin)) setLngDirection(lngDirection * -1);

    return new LatLng(lat, lng);
  }

  useMapEvents({
    moveend: (e) => {
      const map = e.target;
      const newCenter = getNewCenter(map.getCenter());
      map.panTo(newCenter, panOptions);
    }
  });

  const map = useMap();
  const newCenter = getNewCenter(map.getCenter());
  map.panTo(newCenter, panOptions);

  return <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />;
}

const AnimatedMap = () => {
  return (
    <MapContainer
      className='map'
      zoomControl={false}
      dragging={false}
      keyboard={false}
      boxZoom={false}
      scrollWheelZoom={false}
      touchZoom={false}
      doubleClickZoom={false}
      tap={false}
      preferCanvas={true}
      center={new LatLng(59.395, 18.150)}
      zoom={10}>
      <PanningMap />
    </MapContainer>
  );
};

export default AnimatedMap;
