const sessionKey = 'whib.session';

class Session {
  constructor() {
    this.session = null;

    this.set = this.set.bind(this);
    this.clear = this.clear.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);

    try {
      this.session = this.get();
    } catch(e) {
      console.warn(e);
    };
  }

  set(token, userID) {
    try {
      const session = {
        token: token,
        userID: userID
      };

      localStorage.setItem(sessionKey, JSON.stringify(session));
    } catch(e) {
      console.warn(e);
    };
  }

  get() {
    return JSON.parse(localStorage.getItem(sessionKey));
  }

  clear() {
    try {
      this.session = null;

      localStorage.removeItem(sessionKey);
    } catch(e) {
      console.warn(e);
    };
  }

  isLoggedIn() {
    return this.session !== null;
  }
};

export default new Session();

