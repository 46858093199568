const CREATE_USER = 'CREATE_USER';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

const CREATE_DEVICE = 'CREATE_DEVICE';
const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
const CREATE_DEVICE_FAILURE = 'CREATE_DEVICE_FAILURE';

const FETCH_DEVICES = 'FETCH_DEVICES';
const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
const FETCH_DEVICES_FAILURE = 'FETCH_DEVICES_FAILURE';

const FETCH_DEVICE = 'FETCH_DEVICE';
const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS';
const FETCH_DEVICE_FAILURE = 'FETCH_DEVICE_FAILURE';

const FETCH_POINTS_BY_USER_DEVICE = 'FETCH_POINTS_BY_USER_DEVICE';
const FETCH_POINTS_BY_USER_DEVICE_SUCCESS = 'FETCH_POINTS_BY_USER_DEVICE_SUCCESS';
const FETCH_POINTS_BY_USER_DEVICE_FAILURE = 'FETCH_POINTS_BY_USER_DEVICE_FAILURE';
const CLEAR_POINTS_BY_USER_DEVICE_SUCCESS = 'CLEAR_POINTS_BY_USER_DEVICE_SUCCESS';

const FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE = 'FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE';
const FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS = 'FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS';
const FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_FAILURE = 'FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_FAILURE';
const CLEAR_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS = 'CLEAR_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS';

const FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE = 'FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE';
const FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS = 'FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS';
const FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_FAILURE = 'FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_FAILURE';
const CLEAR_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS = 'CLEAR_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS';

const CREATE_COLLECTION_DRAFT_SUCCESS = 'CREATE_COLLECTION_DRAFT_SUCCESS';
const REMOVE_COLLECTION_DRAFT_SUCCESS = 'REMOVE_COLLECTION_DRAFT_SUCCESS';

const CREATE_COLLECTION = 'CREATE_COLLECTION';
const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
const CREATE_COLLECTION_FAILURE = 'CREATE_COLLETION_FAILURE';

const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
const UPDATE_COLLECTION_FAILURE = 'UPDATE_COLLETION_FAILURE';

const SET_FILTER_SUCCESS = 'SET_FILTER_SUCCESS';
const SET_CURRENT_POSITION_SUCCESS = 'SET_CURRENT_POSITION_SUCCESS';

const FETCH_COLLECTIONS_BY_USER_DEVICE = 'FETCH_COLLECTIONS_BY_USER_DEVICE';
const FETCH_COLLECTIONS_BY_USER_DEVICE_SUCCESS = 'FETCH_COLLECTIONS_BY_USER_DEVICE_SUCCESS';
const FETCH_COLLECTIONS_BY_USER_DEVICE_FAILURE = 'FETCH_COLLECTIONS_BY_USER_DEVICE_FAILURE';

const actions = {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,

  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,

  LOGOUT_USER_SUCCESS,

  CREATE_DEVICE,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,

  FETCH_DEVICES,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,

  FETCH_DEVICE,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILURE,

  FETCH_POINTS_BY_USER_DEVICE,
  FETCH_POINTS_BY_USER_DEVICE_SUCCESS,
  FETCH_POINTS_BY_USER_DEVICE_FAILURE,
  CLEAR_POINTS_BY_USER_DEVICE_SUCCESS,

  FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE,
  FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS,
  FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_FAILURE,
  CLEAR_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS,

  FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE,
  FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS,
  FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_FAILURE,
  CLEAR_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS,

  CREATE_COLLECTION_DRAFT_SUCCESS,
  REMOVE_COLLECTION_DRAFT_SUCCESS,

  CREATE_COLLECTION,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILURE,

  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILURE,

  SET_FILTER_SUCCESS,
  SET_CURRENT_POSITION_SUCCESS,

  FETCH_COLLECTIONS_BY_USER_DEVICE,
  FETCH_COLLECTIONS_BY_USER_DEVICE_SUCCESS,
  FETCH_COLLECTIONS_BY_USER_DEVICE_FAILURE
};

export default actions;
