import moment from 'moment';

import actionTypes from 'actions/action-types';
import devicesAPI from 'apis/devices';

function doRequest() {
  return {
    type: actionTypes.FETCH_DEVICES
  };
};

function requestSuccess(res) {
  return {
    type: actionTypes.FETCH_DEVICES_SUCCESS,
    receivedAt: moment.utc(),
    data: res
  };
};

function requestFail(res) {
  return {
    type: actionTypes.FETCH_DEVICES_FAILURE,
    error: res.error
  };
};

export default function fetchDevicesByUser(dispatch) {
  return function(userID, token) {
    dispatch(doRequest());

    return devicesAPI.fetchDevicesByUser(userID, token)
      .then(res => dispatch(requestSuccess(res)))
      .catch(res => dispatch(requestFail(res)))
  };
}
