import Api from 'shared/http/api';
import { APPLICATION_JSON } from 'shared/constants';
import config from 'shared/config';

function createCollection(deviceID, startTime, endTime, name, userID, token) {
  const path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/collections`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .post({
      start_time: startTime.utc().format(),
      end_time: endTime.utc().format(),
      name
    });
};

function updateCollection(userID, deviceID, collectionID, startTime, endTime, name, token) {
  const path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/collections/${collectionID}`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .put({
      start_time: startTime.utc().format(),
      end_time: endTime.utc().format(),
      name
    });
};

const collectionAPI ={
  createCollection,
  updateCollection
};

export default collectionAPI;
