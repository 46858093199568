import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

import{ API_DATETIME_FORMAT, DATETIME_FORMAT, DATE_FORMAT } from 'shared/constants';
import Icon from 'components/icon';

export default class Collection extends Component {
  constructor(props) {
    super(props);

    const { collection } = props;

    this.state = {
      isEditing: false,
      isSaving: false,
      error: null,

      originalName: collection.name,
      originalStartTime: collection.start_time,
      originalEndTime: collection.end_time,
      name: collection.name,
      startTime: moment.utc(collection.start_time),
      endTime: moment.utc(collection.end_time)
    };

    this.collectionAsFilter = this.collectionAsFilter.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.onUpdateName = this.onUpdateName.bind(this);
    this.onUpdateDateTime = this.onUpdateDateTime.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  collectionAsFilter() {
    const { collection, actions } = this.props;

    const filter = {
      name: 'custom',
      start: () => moment.utc(collection.start_time, API_DATETIME_FORMAT).local(),
      end: () => moment.utc(collection.end_time, API_DATETIME_FORMAT).local(),
    };

    actions.setFilter(filter);
  }

  toggleEditing() {
    this.setState(state => {
      return { isEditing: !state.isEditing }
    });
  }

  onUpdateName(e) {
    const { value } = e.target;

    this.setState({ name: value });
  }

  onUpdateDateTime(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'start-date':
      this.setState({ startTime: moment(value, DATE_FORMAT).startOf('day').utc() });
      break;
    case 'start-time':
      this.setState({ startTime: value.second(0).utc() });
      break;
    case 'end-date':
      this.setState({ endTime: moment(value, DATE_FORMAT).endOf('day').utc() });
      break;
    case 'end-time':
      this.setState({ endTime: value.second(0).utc() });
      break;
    default:
      // do nothing
    };
  }

  onSave() {
    const { name, startTime, endTime } = this.state;
    const { session, deviceID, collection, actions } = this.props;

    collection.name = name;
    collection.start_time = startTime;
    collection.end_time = endTime;

    this.setState({ isSaving: true });

    actions.updateCollection(session.data.userID, deviceID, collection.id, collection.start_time, collection.end_time, collection.name, session.data.token)
      .then(res => {
        this.setState({
          isEditing: false,
          isSaving: false,
        });

        if (res.error !== undefined) {
          this.setState({ error: res.error });

          return;
        };

        this.setState({ error: null });
      });
  }

  onCancel() {
    const { originalName, originalStartTime, originalEndTime } = this.state;

    this.setState({
      isEditing: false,
      name: originalName,
      startTime: moment.utc(originalStartTime),
      endTime: moment.utc(originalEndTime)
    });
  }

  render() {
    const { isEditing, isSaving, error, name, startTime, endTime } = this.state;
    const { collection } = this.props;

    return (
      <div className='collection-card card col-12'>
        <div className='collection-header row'>
          <div className='col-10'>
            {isEditing ? (
              <input
                type='text'
                name='name'
                value={name}
                onChange={this.onUpdateName} />
            ) : (
              <h4>{name}</h4>
            )}
          </div>

          <div className='buttons col-2'>
            <button className='button clear icon' disabled={isSaving} onClick={this.toggleEditing}>
              {isEditing ? (
                <Icon name='x' size={20} color='000000' />
              ) : (
                <Icon name='edit-2' size={20} color='000000' />
              )}
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            {isEditing ? (
              <>
                <div className='row'>
                  <p className='col-2 is-center'>Start:</p>

                  <div className='col-6 is-center'>
                    <input
                      className='form-input'
                      type='date'
                      name='start-date'
                      placeholder='Start Filter'
                      value={startTime.local().format(DATE_FORMAT)}
                      onChange={this.onUpdateDateTime} />
                  </div>

                  <div className='col-4 is-center'>
                    <TimePicker
                      style={{ width: '100%' }}
                      showSecond={false}
                      value={startTime.local()}
                      onChange={time => this.onUpdateDateTime({ target: { name: 'start-time', value: time } })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <p className='col-2 is-center'>End:</p>

                  <div className='col-6 is-center'>
                    <input
                      className='form-input'
                      type='date'
                      name='end-date'
                      placeholder='End Filter'
                      value={endTime.local().format(DATE_FORMAT)}
                      onChange={this.onUpdateDateTime} />
                  </div>

                  <div className='col-4 is-center'>
                    <TimePicker
                      style={{ width: '100%' }}
                      showSecond={false}
                      value={endTime.local()}
                      onChange={time => this.onUpdateDateTime({ target: { name: 'end-time', value: time } })}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='row'>
                  <p className='col-2 is-center'>Start:</p>
                  <div className='col-10 is-center'>
                    {startTime.local().format(DATETIME_FORMAT)}<br />
                  </div>
                </div>

                <div className='row'>
                  <p className='col-2 is-center'>End:</p>
                  <div className='col-10 is-center'>
                    {endTime.local().format(DATETIME_FORMAT)}<br />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {isEditing ? (
          <div className='row'>
            <button className='button dark' onClick={this.onSave}>
              Save
            </button>
            <button className='button outline' disabled={isSaving} onClick={this.onCancel}>
              Cancel
            </button>
          </div>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <button className='button dark' onClick={this.collectionAsFilter.bind(this, collection)}>
                Use as filter
              </button>
            </div>
          </div>
        )}

        {error &&
         <div className='row'>
           <div className='col-12'>
             <div className='text-center bd-error'>
               <p className='text-error'>Error:</p>
               {error}
             </div>
           </div>
         </div>
        }
      </div>
    );
  }
}
