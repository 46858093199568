import actionTypes from 'actions/action-types';

export default function setCurrentPosition(dispatch) {
  return function(currentPosition) {
    return dispatch({
      type: actionTypes.SET_CURRENT_POSITION_SUCCESS,
      currentPosition
    });
  };
};
