import { useMap } from 'react-leaflet';
import L from 'leaflet';

export default function SetView({ centerPoint, bounds }) {
  const map = useMap();

  if (!bounds.isValid()) {
    map.setView(centerPoint);
    return null;
  }

  map.fitBounds(bounds, { padding: L.point(70, 70)});
  return null;
};
