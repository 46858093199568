import { connect } from 'react-redux';

import createDevice from 'actions/create-device';
import fetchDevicesByUser from 'actions/fetch-devices-by-user';

import DevicesView from 'components/devices/devices-view';

function mapStateToProps(state) {
  return {
    session: state.session,
    devices: state.devices
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createDevice: createDevice(dispatch),
      fetchDevicesByUser: fetchDevicesByUser(dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesView);

