import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import session from 'reducers/session';
import user from 'reducers/user';
import devices from 'reducers/devices';
import points from 'reducers/points';
import collections from 'reducers/collections';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  session,
  user,
  devices,
  points,
  collections
});

export default createRootReducer;
