import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Collection from 'components/collections/collection';

export default class CollectionsView extends Component {
  constructor(props) {
    super(props);

    const { session, deviceID, actions } = this.props;

    if (deviceID) {
      // TODO add better way to control TTL on state so we don't load
      // all collections every time we mount this component
      actions.fetchCollectionsByUserDevice(session.data.userID, deviceID, session.data.token)
    }
  }

  render() {
    const { session, devices, collections, deviceID, actions } = this.props;

    if (!deviceID) {
      return <p>Select a device from <Link to='/devices'>your Devices</Link></p>;
    }

    const device = devices && devices.data[deviceID];
    const collectionsForDevice = device && collections.data && collections.data[deviceID]
      ? Object.values(collections.data[deviceID])
      : [];

    collectionsForDevice.sort((a, b) => {
      return moment(a.start_time).isBefore(moment(b.start_time))
    });

    return (
      <div className='collections-container container'>
        <div className='collections-list row'>
          {collectionsForDevice.map(collection => (
            <Collection
              key={collection.id}
              session={session}
              deviceID={deviceID}
              collection={collection}
              actions={{
                setFilter: actions.setFilter,
                updateCollection: actions.updateCollection
              }} />
          ))}
        </div>
      </div>
    );
  };
}
