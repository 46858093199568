import initialState from 'shared/initial-state';
import actionTypes from 'actions/action-types';
import { mapData, addData } from './helpers';

const devicesReducer = (state = initialState.devices, action) => {
    switch (action.type) {
      case actionTypes.CREATE_DEVICE:
        return Object.assign({}, state, { loading: true });
      case actionTypes.CREATE_DEVICE_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          lastUpdated: action.receivedAt,
          data: addData(action.data, state.data)
        });
      case actionTypes.CREATE_DEVICE_FAILURE:
        return Object.assign({}, state, { loading: false });

      case actionTypes.FETCH_DEVICES:
        return Object.assign({}, state, { loading: true });
      case actionTypes.FETCH_DEVICES_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          lastUpdated: action.receivedAt,
          data: mapData(action.data, state.data)
        });
      case actionTypes.FETCH_DEVICES_FAILURE:
        return Object.assign({}, state, { loading: false });

      case actionTypes.FETCH_DEVICE:
        return Object.assign({}, state, { loading: true });
      case actionTypes.FETCH_DEVICE_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          lastUpdated: action.receivedAt,
          data: addData(action.data, state.data)
        });
      case actionTypes.FETCH_DEVICE_FAILURE:
        return Object.assign({}, state, { loading: false });

      default:
        return state
    };
};

export default devicesReducer;
