const APPLICATION_JSON = 'application/json';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const API_DATETIME_FORMAT = `YYYY-MM-DDTHH:mm:ss`;

export {
  APPLICATION_JSON,

  DATE_FORMAT,
  DATETIME_FORMAT,
  API_DATETIME_FORMAT
};
