import moment from 'moment';

import Api from 'shared/http/api';
import { APPLICATION_JSON, API_DATETIME_FORMAT } from 'shared/constants';
import config from 'shared/config';

function createDevice(name, userID, token) {
  const path = `${config.apiHost}/api/users/${userID}/devices`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .post({ name })
};

function fetchDevicesByUser(userID, token) {
  const path = `${config.apiHost}/api/users/${userID}/devices`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get()
};

function fetchDeviceByID(userID, deviceID, token) {
  const path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get()
};

function fetchPointsByUserDevice(userID, deviceID, filters, token, result) {
  result = result || [];

  const limit = filters.limit || 1000;
  var path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/points?limit=${limit}`;

  if (filters.start) {
    const start = moment.utc(filters.start).format(API_DATETIME_FORMAT);
    path += `&start=${start}`
  }

  if (filters.start) {
    const end = moment.utc(filters.end).format(API_DATETIME_FORMAT);
    path += `&end=${end}`
  }

  if (filters.northeast && filters.southwest) {
    path += `&northeast_lat=${filters.northeast.lat}&northeast_lng=${filters.northeast.lng}`
    path += `&southwest_lat=${filters.southwest.lat}&southwest_lng=${filters.southwest.lng}`
  }

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get()
    .then(res => {
      result = result.concat(res);

      if (res.length >= limit) {
        const lastPoint = res[res.length - 1];
        filters.start = moment.utc(lastPoint.timestamp);

        return fetchPointsByUserDevice(userID, deviceID, filters, token, result);
      }

      return Promise.resolve(result);
    })
    .catch(res => Promise.reject(res));
};

function fetchBoundsOfPointsByUserDevice(userID, deviceID, filters, token) {
  const start = moment(filters.start).utc().format(API_DATETIME_FORMAT);
  const end = moment(filters.end).utc().format(API_DATETIME_FORMAT);

  var path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/points/bounds?start=${start}&end=${end}`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get();
}

function fetchStatisticsOfPointsByUserDevice(userID, deviceID, filters, token) {
  const start = moment(filters.start).utc().format(API_DATETIME_FORMAT);
  const end = moment(filters.end).utc().format(API_DATETIME_FORMAT);

  var path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/points/statistics?start=${start}&end=${end}`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get();
}

function fetchCollectionsByUserDevice(userID, deviceID, token) {
  const limit = 1000;

  var path = `${config.apiHost}/api/users/${userID}/devices/${deviceID}/collections?limit=${limit}`;

  return new Api()
    .setPath(path)
    .authorization(token)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .get();
}

const deviceAPI = {
  createDevice,
  fetchDevicesByUser,
  fetchDeviceByID,
  fetchPointsByUserDevice,
  fetchBoundsOfPointsByUserDevice,
  fetchStatisticsOfPointsByUserDevice,
  fetchCollectionsByUserDevice
};

export default deviceAPI;
