import React, { Component } from 'react';

export default class Icon extends Component {
  render() {
    const { name, size, color } = this.props;

    return (
      <img src={`https://icongr.am/feather/${name}.svg?size=${size}&color=${color}`} alt="icon" />
    );
  };
};
