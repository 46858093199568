import initialState from 'shared/initial-state';
import actionTypes from 'actions/action-types';

const userReducer = (state = initialState.user, action) => {
    switch (action.type) {
      case actionTypes.CREATE_USER:
        return Object.assign({}, state, { loading: true });
      case actionTypes.CREATE_USER_SUCCESS:
        return Object.assign({}, state, {
          loading: false,
          lastUpdated: action.receivedAt,
          data: action.data
        });
      case actionTypes.CREATE_USER_FAILURE:
        return Object.assign({}, state, { loading: false });
      default:
        return state
    };
};

export default userReducer;
