import Requests from './requests'

export default class Api {
    constructor(path) {
      this.requests = new Requests();
      this.path = "";
    }

    setPath(path) {
      this.path = path;
      return this;
    }

    authorization(value) {
      this.requests.setHeader('Authorization', `Bearer ${value}`);
      return this;
    }

    accept(value) {
      this.requests.setHeader('Accept', value);
      return this;
    }

    contentType(value) {
      this.requests.setHeader('Content-Type', value);
      return this;
    }

    get() { return this.requests.get(this.path) };
    post(data) { return this.requests.post(this.path, data) };
    put(data) { return this.requests.put(this.path, data) };
    delete(data) { return this.requests.delete(this.path, data) };
}
