import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import moment from 'moment';

import createRootReducer from 'reducers';
import session from 'shared/session';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

function configureStore(initialState) {
  if (session.isLoggedIn()) {
    initialState = Object.assign({}, initialState, {
      session: {
        loading: false,
        lastUpdated: moment.utc(),
        data: session.get()
      }
    });
  };

  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history)
      )
    )
  );
};

export default configureStore;
