// Inspired by https://github.com/github/fetch/issues/203#issuecomment-370199549
function parseJSON(response) {
  return new Promise((resolve) => response.json()
    .then((json) => resolve({
      status: response.status,
      ok: response.ok,
      data: json,
    })));
}

function request(method, headers, url, data) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers,
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((response) => {
      if (response.ok) {
        return resolve(response.data);
      };

      return reject(response.data);
    })
    .catch((error) => reject({
      error: error.message,
    }));
  });
}

export default class Requests {
  constructor() {
    this.headers = {};
  }

  setHeader(header, value) {
    if (this.headers[header] === undefined) {
      this.headers[header] = [];
    };

    this.headers[header].push(value);
  }

  get(url) { return request('GET', this.headers, url) }
  post(url, data) {return request('POST', this.headers, url, data) }
  put(url, data) { return request('PUT', this.headers, url, data) }
  delete(url) { return request('DELETE', this.headers, url) }
};


