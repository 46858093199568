import { useMapEvents } from 'react-leaflet';

export default function UpdateBounds({ actions }) {
  useMapEvents({
    moveend: event => {
      actions.boundsChanged(event.sourceTarget.getBounds());
    }
  });

  return null;
}
