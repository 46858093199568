// mapData ensures that state.data is an object with
// each elements id as the key
function mapData(data, existingState) {
  let newState = existingState;

  for (let d of data) {
    newState = Object.assign({}, newState, { [d.id]: d });
  };

  return newState;
}

function addData(data, existingState) {
  return Object.assign({}, existingState, { [data.id]: data });
}

// mapDataToKey maps `data` to a specific key, overwriting
// the existing data for that key
function mapDataToKey(data, key, existingState) {
  // overwrite existing data, if any
  const dataForKey = {};

  return Object.assign({}, existingState, { [key]: mapData(data, dataForKey) });
};

// addDataToKey adds `data` to a specific key, keeping
// the old data (or overwriting keys in `data`)
function addDataToKey(data, key, existingState) {
  var dataForKey = existingState[key];
  if (!dataForKey) {
    dataForKey = {};
  };

  return Object.assign({}, existingState, { [key]: addData(data, dataForKey)});
};

export {
  mapData,
  addData,
  mapDataToKey,
  addDataToKey
};
