const initialState = {
  session: {
    loading: false,
    lastUpdated: null,
    data: {
      userID: null,
      token: null
    },
    filter: {
      // name: string,
      // start: timestamp,
      // end: timestamp
    },
    currentPosition: {
      // latitude: float,
      // longitude: float
    }
  },
  user: {
    loading: false,
    lastUpdated: null,
    data: {
      userID: null
    }
  },
  devices: {
    loading: false,
    lastUpdated: null,
    data: {
      // "<device id>": {
      //   id: int,
      //   name: string,
      //   ...
      // },
    }
  },
  points: {
    loading: false,
    lastUpdate: null,
    data: {
      // "<device id>": [
      //   { latitude: float, longitude: float },
      //   ...
      // ],
      // statistics: {},
      // bounds: [],
    }
  },
  collections: {
    loading: false,
    lastUpdated: null,
    draft: null,
    data: {
      // "<device id>": {
      //   "<collection id>": {
      //     id: int,
      //     name: string,
      //     ...
      //   }
      // }
    }
  }
};

export default initialState;
