import Api from 'shared/http/api';
import { APPLICATION_JSON } from 'shared/constants';
import config from 'shared/config';

function login(email, password) {
  const path = `${config.apiHost}/api/authenticate`;

  return new Api()
    .setPath(path)
    .accept(APPLICATION_JSON)
    .contentType(APPLICATION_JSON)
    .post({ email, password });
};

const sessionAPI = {
  login
};

export default sessionAPI;
