import moment from 'moment';

const endOfToday = () => moment().endOf('day');

const filterPresets = {
  yesterday: {
    name: "Yesterday",
    start: () => moment().startOf('day').subtract(1, 'days'),
    end: () => moment().endOf('day').subtract(1, 'days')
  },
  today: {
    name: "Today",
    start: () => moment().startOf('day'),
    end: endOfToday
  },
  lastWeek: {
    name: "Last week",
    start: () => moment().startOf('day').subtract(7, 'days'),
    end: endOfToday
  },
  lastMonth: {
    name: "Last month",
    start: () => moment().startOf('day').subtract(1, 'months'),
    end: endOfToday
  },
  lastYear: {
    name: "Last year",
    start: () => moment().startOf('day').subtract(1, 'years'),
    end: endOfToday
  }
};

export default filterPresets;
