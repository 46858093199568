import moment from 'moment';

import actionTypes from 'actions/action-types';
import sessionAPI from 'apis/session';
import session from 'shared/session';

function doRequest() {
  return {
    type: actionTypes.LOGIN_USER
  };
};

function requestSuccess(res) {
  session.set(res.token, res.user_id);

  return {
    type: actionTypes.LOGIN_USER_SUCCESS,
    receivedAt: moment.utc(),
    data: {
      token: res.token,
      userID: res.user_id
    }
  };
};

function requestFail(res) {
  return {
    type: actionTypes.LOGIN_USER_FAILURE,
    error: res.error
  };
};

export default function login(dispatch) {
  return function(email, password) {
    dispatch(doRequest());

    return sessionAPI.login(email, password)
      .then(res => dispatch(requestSuccess(res)))
      .catch(res => dispatch(requestFail(res)))
  };
};
