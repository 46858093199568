import initialState from 'shared/initial-state';
import actionTypes from 'actions/action-types';
import { mapDataToKey } from './helpers';

const pointsReducer = (state = initialState.points, action) => {
  switch (action.type) {
  case actionTypes.FETCH_POINTS_BY_USER_DEVICE:
    return Object.assign({}, state, { loading: true });
  case actionTypes.FETCH_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: mapDataToKey(action.data.points, action.data.deviceID, state.data)
    });
  case actionTypes.FETCH_POINTS_BY_USER_DEVICE_FAILURE:
    return Object.assign({}, state, { loading: false });

  case actionTypes.CLEAR_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: Object.assign({}, state.data, { [action.data.deviceID]: [] })
    });

  case actionTypes.FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE:
    return Object.assign({}, state, { loading: true });
  case actionTypes.FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: Object.assign({}, state.data, { statistics: action.data })
    });
  case actionTypes.FETCH_STATISTICS_OF_POINTS_BY_USER_DEVICE_FAILURE:
    return Object.assign({}, state, { loading: false });

  case actionTypes.CLEAR_STATISTICS_OF_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: Object.assign({}, state.data, { statistics: null })
    });

  case actionTypes.FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE:
    return Object.assign({}, state, { loading: true });
  case actionTypes.FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: Object.assign({}, state.data, { bounds: action.data })
    });
  case actionTypes.FETCH_BOUNDS_OF_POINTS_BY_USER_DEVICE_FAILURE:
    return Object.assign({}, state, { loading: false });

  case actionTypes.CLEAR_BOUNDS_OF_POINTS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: Object.assign({}, state.data, { bounds: null })
    });

  default:
    return state
  };
};

export default pointsReducer;
