import { useEffect } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import L, { LatLng } from 'leaflet';

const getCurrentPosition = (map, setCurrentPosition) => {
  if (!window.navigator) {
    return null;
  }

  const options = { enableHighAccuracy: true };

  window.navigator.geolocation.getCurrentPosition(
    pos => {
      const currentPosition = {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      };

      setCurrentPosition(currentPosition);
      map.setView(new LatLng(currentPosition.latitude, currentPosition.longitude));
    }, null, options);
};

export default function CurrentPositionControl({ actions }) {
  const { setCurrentPosition } = actions;

  const context = useLeafletContext();

  const button = L.Control.extend({
    position: 'topleft',
    onAdd: (map) => {
      const button = L.DomUtil.create('button', 'button dark is-center current-position');
      button.addEventListener('click', () => getCurrentPosition(map, setCurrentPosition));

      const icon = L.DomUtil.create('img', '', button);
      icon.src = `https://icongr.am/feather/disc.svg?size=20&color=ffffff`

      return button;
    },
    onRemove: (map) => {}
  });

  useEffect(() => {
    const container = context.layerContainer || context.map;
    const b = new button();
    container.addControl(b);

    return () => {
      container.removeControl(b);
    };
  });

  return null;
};
