import initialState from 'shared/initial-state';
import actionTypes from 'actions/action-types';
import { mapDataToKey, addDataToKey } from './helpers';

const collectionsReducer = (state = initialState.collections, action) => {
  switch (action.type) {
  case actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE:
    return Object.assign({}, state, { loading: true });
  case actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: mapDataToKey(action.data.collections, action.data.deviceID, state.data)
    });
  case actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE_FAILURE:
    return Object.assign({}, state, { loading: false });

    case actionTypes.CREATE_COLLECTION_DRAFT_SUCCESS:
    return Object.assign({}, state, { draft: action.draft });
  case actionTypes.REMOVE_COLLECTION_DRAFT_SUCCESS:
    return Object.assign({}, state, { draft: null });

  case actionTypes.CREATE_COLLECTION:
    return Object.assign({}, state, { loading: true });
  case actionTypes.CREATE_COLLECTION_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: addDataToKey(action.data.collection, action.data.deviceID, state.data)
    });
  case actionTypes.CREATE_COLLECTION_FAILURE:
    return Object.assign({}, state, { loading: false });

  case actionTypes.UPDATE_COLLECTION_COLLECTION:
    return Object.assign({}, state, { loading: true });
  case actionTypes.UPDATE_COLLECTION_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: addDataToKey(action.data.collection, action.data.deviceID, state.data)
    });
  case actionTypes.UPDATE_COLLECTION_FAILURE:
    return Object.assign({}, state, { loading: false });

  default:
    return state
  };
};

export default collectionsReducer;
