import actionTypes from 'actions/action-types';

export default function setFilter(dispatch) {
  return function(filter) {
    return dispatch({
      type: actionTypes.SET_FILTER_SUCCESS,
      filter
    });
  };
};
