import actionTypes from 'actions/action-types';

export default function createCollectionDraft(dispatch) {
  return function(userID, deviceID, deviceName, startTime, endTime) {

    return dispatch({
      type: actionTypes.CREATE_COLLECTION_DRAFT_SUCCESS,
      draft: { userID, deviceID, deviceName, startTime, endTime }
    });
  };
};
