import React, { Component } from 'react';

export default class LoginView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      email: "",
      password: ""
    };

    this.onChange = this.onChange.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.submit = this.submit.bind(this);
    this.onEnterSubmit = this.onEnterSubmit.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState(() => {
      return { [name]: value };
    });
  }

  validate(request) {
    return request.email !== "" && request.password !== "";
  }

  canSubmit() {
    const { email, password } = this.state;
    return email !== "" && password !== "";
  }

  submit() {
    const { email, password } = this.state;
    const { actions } = this.props;

    actions.login(email, password)
      .then(res => {
        if (res.error !== undefined) {
          this.setState(() => {
            return {
              error: "Email or password is not correct",
              password: ""
            }
          });

          return;
        };

        this.setState(() => {
          return {
            error: null,
            email: "",
            password: ""
          }
        });

        actions.redirectToRoot();
      });
  }

  onEnterSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (this.canSubmit()) {
        this.submit();
      }
    }
  }

  render() {
    const { user } = this.props;
    const { email, password, error } = this.state;

    return (
      <div className='login-container container'>
        <div className='row is-horizontal-align'>
          <div className='col-8'>
            <div className={`card ${error && 'text-error bd-error'}`}>
              <h4 className='is-horizontal-align'>Login</h4>

              <div className='row'>
                <div className='col-12'>
                  <label>Email</label>
                    <input
                      type='text'
                      name='email'
                      placeholder='Email'
                      value={email}
                      onChange={this.onChange}
                      onKeyDown={this.onEnterSubmit} />
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                <label>Password</label>
                  <input
                    type='password'
                    name='password'
                    placeholder='Password'
                    value={password}
                    onChange={this.onChange}
                    onKeyDown={this.onEnterSubmit} />
                </div>
              </div>

              {error &&
                <div className='row'>
                  <div className='col-12'>
                    <div className='text-center'>
                      <p className='text-strong'>Error:</p>
                      {error}
                    </div>
                  </div>
                </div>
              }

              <div className='row'>
                <div className='col-12'>
                  <button className='button dark is-full-width' type='text' onClick={this.submit} disabled={user.loading || !this.canSubmit()}>LOGIN</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
