import React, { Component } from 'react';

import Navigation from 'components/navigation/navigation-view';
import Map from 'components/map/map-view';

export default class RootView extends Component {
  constructor(props) {
    super(props);

    const { session, actions } = this.props;

    const isLoggedIn = session.data.token;
    if (!isLoggedIn) {
      actions.redirectToRoot();
    }
  }

  render() {
    const { session, devices, deviceID, points, collections, start, end, actions } = this.props;
    const {
      push,
      logout,
      redirectToRoot,
      fetchPointsByUserDevice,
      clearPointsByUserDevice,
      fetchBoundsOfPointsByUserDevice,
      clearBoundsOfPointsByUserDevice,
      fetchStatisticsOfPointsByUserDevice,
      clearStatisticsOfPointsByUserDevice,
      fetchDeviceByID,
      createCollectionDraft,
      removeCollectionDraft,
      createCollection,
      setFilter,
      setCurrentPosition,
    } = actions;

    const isLoggedIn = session.data.token;

    return (
      <>
        <Navigation
          isLoggedIn={isLoggedIn}
          actions={{ logout, redirectToRoot }} />

        <Map
          session={session}
          devices={devices}
          deviceID={deviceID}
          points={points}
          collections={collections}
          start={start}
          end={end}
          actions={{
            push,
            fetchPointsByUserDevice,
            clearPointsByUserDevice,
            fetchBoundsOfPointsByUserDevice,
            clearBoundsOfPointsByUserDevice,
            fetchStatisticsOfPointsByUserDevice,
            clearStatisticsOfPointsByUserDevice,
            fetchDeviceByID,
            createCollectionDraft,
            removeCollectionDraft,
            createCollection,
            setFilter,
            setCurrentPosition }} />
      </>
    );
  };
};
