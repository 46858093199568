import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NavigationView extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    const { actions } = this.props;

    actions.logout();
    actions.redirectToRoot();
  }

  render() {
    const { isLoggedIn } = this.props;

    return (
      <nav className='navigation nav'>
        {!isLoggedIn ? (
          <div className='nav-right'>
            <Link to='/login' className='button dark'>LOG IN</Link>
          </div>
        ) : (
          <>
            <div className='nav-left'>
              <Link to='/devices' className='button dark'>DEVICES</Link>
            </div>
            <div className='nav-right'>
              <button className='button dark outline' onClick={this.logout}>LOG OUT</button>
            </div>
          </>
        )}
      </nav>
    );
  };
}
