import 'css/whib.css';
import 'leaflet/dist/leaflet.css';
import 'rc-time-picker/assets/index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';

import Root from 'containers/root';
import Devices from 'containers/devices';
import Collections from 'containers/collections';
import Login from 'containers/login';

import initialState from 'shared/initial-state';
import configureStore, { history } from './store';

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={Root} />

      <Route path="/devices" component={Devices} />
      <Route path="/collections" component={Collections} />

      <Route path="/login" component={Login} />
    </ConnectedRouter>
  </Provider>
, document.getElementById('root'));

serviceWorker.unregister();

