import React, { Component } from 'react';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export default class CollectionDraftModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      createSuccess: false,
      name: ""
    };

    this.onChange = this.onChange.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState(() => {
      return { [name]: value };
    });
  }

  canSubmit() {
    const { name } = this.state;
    return name !== "";
  }

  cancel() {
    const { actions } = this.props;

    this.setState(() => {
      return { name: "" };
    });

    actions.removeCollectionDraft();
  }

  submit() {
    const { session, collections, actions } = this.props;
    const { name } = this.state;

    const { draft } = collections;

    if (this.canSubmit()) {
      actions.createCollection(draft.deviceID, draft.startTime, draft.endTime, name, session.data.userID, session.data.token)
        .then(res => {
          if (res.error !== undefined) {
            this.setState(() => {
              return { error: res.error };
            });

            return;
          };

          this.setState(() => {
            return {
              error: null,
              createSuccess: true,
              name: ""
            }
          });
        });
    };
  }

  render() {
    const { collections, actions } = this.props;
    const { name, error, createSuccess } = this.state;

    const { draft } = collections;

   return (
      <div className='collection-draft-modal-container container'>
        <div className='row is-horizontal-align'>
          <div className='col-8'>
            <div className={`card ${error && 'text-error bd-error'}`}>
              <h4 className='is-horizontal-align'>Collection draft</h4>

              {createSuccess ? (
                <div className='row'>
                  <div className='col-12'>
                    <div className='text-center'>
                      <p>Collection created!</p>
                      <button className='button dark' onClick={actions.removeCollectionDraft}>OK</button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <p>
                        Creating Collection for <span className='text-strong'>{draft.deviceName}</span>.<br />
                          It starts at <span className='text-strong'>{draft.startTime.format(DATE_TIME_FORMAT)}</span> and ends at <span className='text-strong'>{draft.endTime.format(DATE_TIME_FORMAT)}</span>.
                      </p>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                    <label>Collection Name</label>
                      <input
                        type='text'
                        name='name'
                        placeholder='Collection Name'
                        value={name}
                        onChange={this.onChange} />
                      </div>
                  </div>

                  {error &&
                    <div className='row'>
                      <div className='col-12'>
                        <div className='text-center'>
                          <p className='text-strong'>Error:</p>
                          {error}
                        </div>
                      </div>
                    </div>
                  }

                  <div className='row'>
                    <div className='col-6'>
                      <button className='button dark is-full-width' type='text' onClick={this.submit} disabled={collections.loading || !this.canSubmit()}>CREATE</button>
                    </div>

                    <div className='col-6'>
                      <button className='button dark outline is-full-width' type='text' onClick={this.cancel} disabled={collections.loading}>CANCEL</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
