import moment from 'moment';

import actionTypes from 'actions/action-types';
import deviceAPI from 'apis/devices';

function doRequest() {
  return {
    type: actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE
  };
};

function requestSuccess(res, deviceID) {
  return {
    type: actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE_SUCCESS,
    receivedAt: moment.utc(),
    data: { deviceID, collections: res }
  };
};

function requestFail(res) {
  return {
    type: actionTypes.FETCH_COLLECTIONS_BY_USER_DEVICE_FAILURE,
    error: res.error
  };
};

export default function fetchCollectionsByUserDevice(dispatch) {
  return function(userID, deviceID, token) {
    dispatch(doRequest());

    return deviceAPI.fetchCollectionsByUserDevice(userID, deviceID, token)
      .then(res => dispatch(requestSuccess(res, deviceID)))
      .catch(res => dispatch(requestFail(res)))
  };
}
