import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class DeviceList extends Component {
  render() {
    const { devices } = this.props;

    return (
      <div className='devices-list container'>
        {devices.loading ? (
          <div className='row'>
            <div className='col-12 is-center'>
              <p className='text-strong'>LOADING</p>
            </div>
          </div>
        ) : (
          <>
            {Object.values(devices.data).map(device => {
              const deviceParam = `device=${device.id}`;

              return (
                <div className='row' key={device.id}>
                  <div className='col col is-left'>
                    <Link to={`/?${deviceParam}`}>{device.name}</Link>
                  </div>
                  <div className='col col is-right'>
                    <Link to={`/collections?${deviceParam}`}>Collections</Link>
                  </div>
                </div>
            )})}
          </>
        )}
      </div>
    );
  };
};
