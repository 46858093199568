import moment from 'moment';

import actionTypes from 'actions/action-types';
import collectionsAPI from 'apis/collections';

function doRequest() {
  return {
    type: actionTypes.CREATE_COLLECTION
  };
};

function requestSuccess(res, deviceID) {
  return {
    type: actionTypes.CREATE_COLLECTION_SUCCESS,
    receivedAt: moment.utc(),
    data: { deviceID, collection: res }
  };
};

function requestFail(res) {
  return {
    type: actionTypes.CREATE_COLLECTION_FAILURE,
    error: res.error
  };
};

export default function createCollection(dispatch) {
  return function(deviceID, startTime, endTime, name, userID, token) {
    dispatch(doRequest());

    return collectionsAPI.createCollection(deviceID, startTime, endTime, name, userID, token)
      .then(res => dispatch(requestSuccess(res, deviceID)))
      .catch(res => dispatch(requestFail(res)))
  };
}
