import React, { Component } from 'react';
import moment from 'moment';
import { LatLng } from 'leaflet';

import { API_DATETIME_FORMAT } from 'shared/constants';
import AnimatedMap from 'components/map/animated-map';
import Map from 'components/map/map';
import CollectionDraftModal from 'components/map/collection-draft-modal';
import PointStatistics from 'components/map/point-statistics';
import MapControls from 'components/map/map-controls';

export default class MapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      showStatistics: false,

      fetchWithTime: true,

      bounds: null,
      fetchWithBounds: false,
    };

    this.boundsChanged = this.boundsChanged.bind(this);
    this.toggleStatistics = this.toggleStatistics.bind(this);
    this.toggleFetchWithTime = this.toggleFetchWithTime.bind(this);
    this.toggleFetchWithBounds = this.toggleFetchWithBounds.bind(this);
    this.fetchPointsForDevice = this.fetchPointsForDevice.bind(this);
    this.createCollectionDraft = this.createCollectionDraft.bind(this);

    const { session, devices, deviceID, actions } = props;

    // We need to load the device if its not already loaded
    if (deviceID && !devices.data[deviceID]) {
      actions.fetchDeviceByID(session.data.userID, deviceID, session.data.token)
    }
  }

  componentDidMount() {
    const { start, end, actions } = this.props;

    // If query parameters are set then use them as filter
    if (start !== undefined && end !== undefined) {
      const filter = {
        name: 'custom',
        start: () => moment(start, API_DATETIME_FORMAT),
        end: () => moment(end, API_DATETIME_FORMAT),
      }

      actions.setFilter(filter);
    }
  }

  componentDidUpdate(prevProps) {
    const { session, deviceID } = this.props;

    const { filter } = session;
    const {filter: prevFilter } = prevProps.session;

    // Check if we had a filter before and if it was different from the current one
    if (prevFilter && prevFilter.start && prevFilter.end) {
      const startChanged = filter.start().format() !== prevFilter.start().format();
      const endChanged = filter.end().format() !== prevFilter.end().format();

      // Recalculate if a new filter should be set from query parameters (start, end)
      if (startChanged || endChanged) {
        if (deviceID) {
          this.updateQueryParams({
            deviceID,
            start: filter.start().format(API_DATETIME_FORMAT),
            end: filter.end().format(API_DATETIME_FORMAT)
          });
        }
      }
    }

    // Recalculate if we want to remove the errors from a previous device
    if(deviceID && deviceID !== prevProps.deviceID) {
      this.setState(() => {
        return { error: null };
      });
    }
  }

  updateQueryParams(params) {
    const { actions } = this.props;

    actions.push(`/?device=${params.deviceID}&start=${params.start}&end=${params.end}`);
  }

  boundsChanged(bounds) {
    this.setState({ bounds });
  }

  toggleStatistics() {
    this.setState(state => {
      return { showStatistics: !state.showStatistics }
    })
  }

  toggleFetchWithTime() {
    this.setState(state => {
      return { fetchWithTime: !state.fetchWithTime }
    })
  }

  toggleFetchWithBounds() {
    this.setState(state => {
      return { fetchWithBounds: !state.fetchWithBounds }
    })
  }

  fetchPointsForDevice() {
    const { session, devices, deviceID, actions } = this.props;
    const { fetchWithTime, bounds, fetchWithBounds } = this.state;

    const device = devices.data[deviceID];

    if (device !== undefined) {
      actions.clearStatisticsOfPointsByUserDevice();

      // Date filter
      const { filter } = session;

      const filters = {
        limit: 5000
      };

      if (fetchWithTime && filter) {
        filters.start = filter.start();
        filters.end = filter.end();
      };

      if (fetchWithBounds && bounds) {
        filters.northeast = bounds.getNorthEast();
        filters.southwest = bounds.getSouthWest();
      };

      // If points are fetched with viewport bounds then any
      // previous bounds (based on fetched points) are cleared
      if (fetchWithBounds) {
        actions.clearBoundsOfPointsByUserDevice();
      }

      actions.fetchPointsByUserDevice(session.data.userID, device.id, filters, session.data.token)
        .then(res => {
          if (res.error !== undefined) {
            // If we had some problem fetching points oe did not find any
            // for the selected filterthen we presumably want to fetch
            // new ones, so keep the controls open and show the error
            this.setState(() => {
              return { error: res.error, showControls: true };
            });

            return;
          };

          this.setState(() => {
            return { error: null }
          });

          // Fetch bonds based on the points being fetched as the viewport bounds are not used
          if (!fetchWithBounds) {
            actions.fetchBoundsOfPointsByUserDevice(session.data.userID, device.id, filters, session.data.token);
          }

          actions.fetchStatisticsOfPointsByUserDevice(session.data.userID, device.id, filters, session.data.token);
      });
    }
  }

  createCollectionDraft() {
    const { session, devices, deviceID, actions } = this.props;
    const { filter} = session;

    const device = devices.data[deviceID];

    actions.createCollectionDraft(session.data.userID, deviceID, device.name, filter.start(), filter.end());
  }

  render() {
    const { showStatistics, fetchWithTime, fetchWithBounds, error } = this.state;
    const { session, devices, deviceID, points, collections, actions } = this.props;

    const { filter } = session;

    const {
      createCollection,
      removeCollectionDraft,
      setFilter,
      setCurrentPosition,
      clearPointsByUserDevice,
      clearStatisticsOfPointsByUserDevice
    } = actions;

    const device = devices.data[deviceID];
    const pointsForDevice = device && points.data && points.data[deviceID]
          ? Object.values(points.data[deviceID])
          : [];

    const statistics = points && points.data && points.data.statistics
          ? points.data.statistics
          : null;

    const bounds = points && points.data && points.data.bounds
          ? points.data.bounds.map(p => new LatLng(p.latitude, p.longitude))
          : [];

    const currentPosition = session.currentPosition ? session.currentPosition : null;

    return (
      <>
        {!pointsForDevice.length ? (
         <AnimatedMap />
        ) : (
          <Map
            deviceID={deviceID}
            points={pointsForDevice}
            bounds={bounds}
            currentPosition={currentPosition}
            actions={{
              setCurrentPosition,
              clearPointsByUserDevice,
              clearStatisticsOfPointsByUserDevice,
              boundsChanged: this.boundsChanged }} />
        )}

        {statistics && showStatistics &&
         <PointStatistics
           statistics={statistics}
           points={pointsForDevice}
           deviceID={deviceID}
           actions={{ setFilter }} />
        }

        {collections.draft &&
         <CollectionDraftModal
           session={session}
           collections={collections}
           actions={{ createCollection, removeCollectionDraft }} />
        }

        <MapControls
          device={device}
          hasStatistics={statistics !== null}
          filter={filter}
          fetchWithTime={fetchWithTime}
          fetchWithBounds={fetchWithBounds}
          loading={points.loading}
          error={error}
          actions={{
            setFilter,
            toggleStatistics: this.toggleStatistics,
            toggleFetchWithTime: this.toggleFetchWithTime,
            toggleFetchWithBounds: this.toggleFetchWithBounds,
            fetchPointsForDevice: this.fetchPointsForDevice,
            createCollectionDraft: this.createCollectionDraft }} />
      </>
    );
  };
}
