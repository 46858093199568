import { connect } from 'react-redux';
import qs from 'query-string';

import fetchCollectionsByUserDevice from 'actions/fetch-collections-by-user-device';
import updateCollection from 'actions/update-collection';
import setFilter from 'actions/set-filter';

import CollectionsView from 'components/collections/collections-view';

function mapStateToProps(state, ownProps) {
  const queryParams = qs.parse(ownProps.location.search);
  const deviceID = Number(queryParams.device);

  return {
    session: state.session,
    devices: state.devices,
    collections: state.collections,
    deviceID
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchCollectionsByUserDevice: fetchCollectionsByUserDevice(dispatch),
      updateCollection: updateCollection(dispatch),
      setFilter: setFilter(dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsView);

