import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import login from 'actions/login';

import LoginView from 'components/login-view';

function mapStateToProps(state) {
  return {
    session: state.session,
    user: state.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: login(dispatch),
      redirectToRoot: () => dispatch(push("/"))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
