import React, { Component } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

import { DATE_FORMAT } from 'shared/constants';
import Icon from 'components/icon';
import filterPresets from 'components/map/filter-presets';

export default class MapControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      showControls: false
    }

    this.toggleControls = this.toggleControls.bind(this);
    this.setFilterPreset = this.setFilterPreset.bind(this);
    this.onFilterRangeChange = this.onFilterRangeChange.bind(this);
  }

  componentDidMount() {
    const { filter, actions } = this.props;

    // Set default if no filter is set (eg. via query parameters)
    if (filter === undefined) {
      actions.setFilter(filterPresets['today']);
    }
  }

  toggleControls() {
    this.setState(state => {
      return { showControls: !state.showControls }
    })
  }

  setFilterPreset(e) {
    const { actions } = this.props;
    const selectedFilter = e.target.value;

    const filter = Object.values(filterPresets).find(p => {
      return p.name === selectedFilter;
    }) || filterPresets['today'];

    actions.setFilter(filter);
  }

  onFilterRangeChange(e) {
    const { filter, actions } = this.props;
    const { name, value } = e.target;

    const custom = {
      name: 'custom',
      start: filter.start,
      end: filter.end
    };

    switch (name) {
    case 'start-date':
      custom['start'] = () => moment(value, DATE_FORMAT).startOf('day');
      break;
    case 'start-time':
      custom['start'] = () => value;
      break;
    case 'end-date':
      custom['end'] = () => moment(value, DATE_FORMAT).endOf('day');
      break;
    case 'end-time':
      custom['end'] = () => value;
      break;
    default:
      // do nothing
    }

    actions.setFilter(custom);
  }

  render() {
    const { device, hasStatistics, filter, fetchWithTime, fetchWithBounds, loading, error, actions } = this.props;
    const { showControls } = this.state;

    return (
      <div className='map-controls'>
        <div className='row'>
          <div className='col-12'>
            <button className='button clear is-center is-full-width' onClick={this.toggleControls}>
              <span className='text-dark'>{device && device.name}</span>

              {showControls ? (
                <Icon name='chevron-down' size={20} color='000000' />
              ) : (
                <Icon name='chevron-up' size={20} color='000000' />
              )}
            </button>
          </div>
        </div>

        {error &&
          <div className='row'>
            <div className='col-12'>
              <div className='text-center bd-error'>
                <p className='text-error'>Error:</p>
                {error}
              </div>
            </div>
          </div>
        }

        {showControls &&
         <div>
           <div className='row'>
             <div className='col-4'>
               <select
                 value={filter.name}
                 onChange={this.setFilterPreset}>
                 {Object.values(filterPresets).map(preset => {
                   return (
                     <option key={preset.name} value={preset.name}>{preset.name}</option>
                   )
                 })}

                 {filter.name === 'custom' &&
                  <option key='custom' value='custom'>Custom</option>
                 }

               </select>
             </div>

             <div className='col-4'>
               <div className='row'>
                 <div className='col-12'>
                   <input
                     className='form-input'
                     type='date'
                     name='start-date'
                     placeholder='Start Filter'
                     value={filter.start().format(DATE_FORMAT)}
                     onChange={this.onFilterRangeChange} />
                 </div>
               </div>

               <div className='row'>
                 <div className='col-12'>
                   <TimePicker
                     style={{ width: '100%' }}
                     showSecond={false}
                     value={filter.start()}
                     onChange={time => this.onFilterRangeChange({ target: { name: 'start-time', value: time } })}
                   />
                 </div>
               </div>
             </div>

             <div className='col-4'>
               <div className='row'>
                 <div className='col-12'>
                   <input
                     className='form-input'
                     type='date'
                     name='end-date'
                     placeholder='End Filter'
                     value={filter.end().format(DATE_FORMAT)}
                     onChange={this.onFilterRangeChange} />
                 </div>
               </div>

               <div className='row'>
                 <div className='col-12'>
                   <TimePicker
                     style={{ width: '100%' }}
                     showSecond={false}
                     value={filter.end()}
                     onChange={time => this.onFilterRangeChange({ target: { name: 'end-time', value: time } })}
                   />
                 </div>
               </div>
             </div>
           </div>

           <div className='row'>
             <div className='col-6'>
               <button
                 className='button dark is-full-width is-full-height'
                 onClick={actions.fetchPointsForDevice}
                 disabled={loading || !device || !(fetchWithTime || fetchWithBounds)}>
                 {loading ? 'Loading' : 'Fetch Points'}
               </button>
             </div>

             <div className='col-6'>
               <div className='row'>
                 <div className='col-4'>
                   Fetch with:
                 </div>

                 <div className='col-8'>
                   <div className='row'>
                     <div className='col-12'>
                       <label>
                         <input
                           type='checkbox'
                           checked={fetchWithTime}
                           onChange={actions.toggleFetchWithTime} />
                         Time
                       </label>
                     </div>
                   </div>

                   <div className='row'>
                     <div className='col-12'>
                       <label>
                         <input
                           type='checkbox'
                           checked={fetchWithBounds}
                           onChange={actions.toggleFetchWithBounds} />
                         Bounds
                       </label>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className='row'>
             <div className='col-6'>
               <button
                 className='button dark is-full-width is-full-height'
                 onClick={actions.createCollectionDraft}
                 disabled={loading || !device}>
                 Create collection
               </button>
             </div>

             <div className='col-6'>
               <button
                 className='button dark is-full-width is-full-height'
                 onClick={actions.toggleStatistics}
                 disabled={loading || !device || !hasStatistics}>
                 Toggle statistics
               </button>
             </div>
           </div>
         </div>
        }
      </div>
    );
  }
};
