import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { DATE_FORMAT } from 'shared/constants';

export default class PointStatistics extends Component {
  formatSpeed(speed) {
    return `${speed * 3.6} km/h`
  }

  formatLength(length) {
    if (length > 1000) {
      return `${length / 1000} km`;
    }

    return `${length} m`;
  }

  generateDateTree(points) {
    return points.reduce((tree, p) => {
      const d = moment(p.timestamp);

      const year = d.year();
      const month = d.month();
      const day = d.date()

      if (!tree[year]) {
        tree[year] = {
          name: d.format('YYYY'),
          children: {}
        };
      }

      if (!tree[year].children[month]) {
        tree[year].children[month] = {
          name: d.format('MMMM'),
          children: {}
        };
      }

      if (!tree[year].children[month].children[day]) {
        tree[year].children[month].children[day] = {
          name: d.format(DATE_FORMAT)
        };
      }

      return tree;
    }, {});
  }

  renderTree(parent, deviceID, setFilter) {
    return (
      <ul>
        {Object.keys(parent)
         .sort((a, b) => parseInt(a) < parseInt(b))
         .map((key) => {
           const name = parent[key].name;

           // We're at the depth of days; make links
           if (!parent[key].children) {
             const filter = {
               name: 'custom',
               start: () => moment(name, DATE_FORMAT).startOf('day'),
               end: () => moment(name, DATE_FORMAT).endOf('day')
             };

             return (
               <li key={name}>
                 <Link
                   key={name}
                   to={`/?device=${deviceID}&start=${name}&end=${name}`}
                   onClick={setFilter.bind(this, filter)}>
                   {moment(name).format('dddd Do')}
                 </Link>
               </li>
             );
           };

           return (
             <li key={name}>
               {name}

               {parent[key].children && this.renderTree(parent[key].children, deviceID, setFilter)}
             </li>
           );
         })}
      </ul>
    );
  }

  render() {
    const { statistics, points, deviceID, actions } = this.props;

    const tree = this.generateDateTree(points);

    return (
      <div className='statistics-container container is-center'>
        <div className='statistics card col-12'>
          <div className='row'>
            <div className='col-12'>
              <ul>
                <li>Average speed: {this.formatSpeed(statistics.average_speed)}</li>
                <li>Min speed: {this.formatSpeed(statistics.min_speed)}</li>
                <li>Max speed: {this.formatSpeed(statistics.max_speed)}</li>
                <li>Length: {this.formatLength(statistics.length)}</li>
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              {this.renderTree(tree, deviceID, actions.setFilter)}
            </div>
          </div>
        </div>
      </div>
    );
  };
};
