import React, { Component } from 'react';

export default class CreateDevice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      name: ""
    };

    this.onChange = this.onChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState(() => {
      return { [name]: value };
    });
  }

  canSubmit() {
    const { name } = this.state;
    return name !== "";
  }

  cancel() {
    const { actions } = this.props;

    this.setState(() => {
      return { name: "" };
    });

    actions.onDecision();
  }

  submit() {
    const { session, actions } = this.props;
    const { name } = this.state;

    if (this.canSubmit()) {
      actions.createDevice(name, session.data.userID, session.data.token)
        .then(res => {
          if (res.error !== undefined) {
            this.setState(() => {
              return { error: res.error };
            });

            return;
          };

          this.setState(() => {
            return {
              error: null,
              name: ""
            }
          });

          actions.onDecision();
        });
    };
  }

  render() {
    const { devices } = this.props;
    const { name, error } = this.state;

    return (
      <>
        {error &&
          <div className='row'>
            <div className='col-12'>
              <div className='text-center bd-error'>
                <p className='text-error'>Error:</p>
                {error}
              </div>
            </div>
          </div>
        }

        <div className='row'>
          <div className='col-12'>
          <label>Name</label>
            <input
              type='text'
              name='name'
              placeholder='Device Name'
              value={name}
              onChange={this.onChange} />
            </div>
        </div>

        <div className='row'>
          <div className='col-6'>
            <button className='button dark is-full-width' type='text' onClick={this.submit} disabled={devices.loading || !this.canSubmit()}>CREATE</button>
          </div>
          <div className='col-6'>
            <button className='button dark outline is-full-width' type='text' onClick={this.cancel} disabled={devices.loading}>CANCEL</button>
          </div>
        </div>
      </>
    );
  };
}
