import actionTypes from 'actions/action-types';
import session from 'shared/session';

export default function logout(dispatch) {
  return function() {
    session.clear();

    return dispatch({
      type: actionTypes.LOGOUT_USER_SUCCESS
    });
  };
};
