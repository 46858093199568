import React, { Component } from 'react';
import { MapContainer, Circle, CircleMarker, Popup, TileLayer } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import moment from 'moment';

import SetView from 'components/map/set-view';
import UpdateBounds from 'components/map/update-bounds';
import CurrentPositionControl from 'components/map/current-position-control';
import { DATETIME_FORMAT } from 'shared/constants';

export default class Map extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      points: nextPoints,
      currentPosition: nextCurrentPosition,
      bounds: nextBounds
    } = nextProps;

    const { points, bounds, currentPosition } = this.props;

    // Re-render as we're about to receive the current position
    if (!currentPosition && nextCurrentPosition) {
      return true;
    }

    // Re-render as the current position has changed
    if (currentPosition && currentPosition !== nextCurrentPosition) {
      return true;
    }

    // Render as we're about to receive bounds
    if (nextBounds.length && !bounds.length) {
      return true;
    }

    // Re-render if bounds differ (assumes bounds always are sorted)
    for (var i = 0; i < nextBounds.length; i++) {
      if (nextBounds[i].lat !== bounds[i].lat || nextBounds[i].lng !== bounds[i].lng) {
        return true;
      }
    }

    // If no points are passed then nothing needs to be updated
    if (!nextPoints.length && !points.length) {
      return false;
    }

    // Render as we're about to receive points
    if (nextPoints.length && !points.length) {
      return true;
    }

    // Re-render if either:
    // 1. Number of points differ. This means we have more/less points
    const numberOfPointsDiffer = nextPoints.length !== points.length;

    // 2. The first points of the old and new points differ. This means that the
    //    filtered time range has been expanded backwards in time
    const firstPointsDiffer = nextPoints[0].timestamp !== points[0].timestamp;

    // 3. The last points of the old and new points differ. This means that the
    //    filtered time range has been expanded forwards in time
    const lastPointsDiffer = nextPoints[nextPoints.length -1].timestamp !== points[points.length - 1].timestamp;

    return numberOfPointsDiffer || firstPointsDiffer || lastPointsDiffer;
  }

  componentWillUnmount() {
    const { deviceID, actions } = this.props;

    actions.clearPointsByUserDevice(deviceID);
    actions.clearStatisticsOfPointsByUserDevice();
  }

  render() {
    const { points, bounds, currentPosition, actions } = this.props;

    // Default center over Stockholm
    var center = { latitude: 59.325, longitude: 18.050 };

    if (points.length) {
      center = points[Math.floor(points.length / 2)];
    }

    console.info(`Rendering ${points.length} points`);

    return (
      <MapContainer
        className='map'
        zoomControl={false}
        preferCanvas={true}
        zoomSnap={0}
        zoom={10}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <UpdateBounds actions={{ boundsChanged: actions.boundsChanged }}/>
        <SetView
          centerPoint={new LatLng(center.latitude, center.longitude)}
          bounds={L.latLngBounds(bounds)} />

        <CurrentPositionControl actions={{ setCurrentPosition: actions.setCurrentPosition }} />

        {currentPosition &&
         <Circle
           pathOptions={{ fillColor: 'red', color: 'red' }}
           center={[currentPosition.latitude, currentPosition.longitude]} radius={6} />
        }

        {points && points.length && points.map((p, i) => {
          const center = new LatLng(p.latitude, p.longitude);

          return (
            <CircleMarker key={i} center={center} radius={3}>
              <Popup>
                <ul>
                  <li><strong>Timestamp: {moment(p.timestamp).format(DATETIME_FORMAT)}</strong></li>
                  <li>Latitude: {p.latitude}</li>
                  <li>Longitude: {p.longitude}</li>
                  <li>Speed: {(p.speed * 3.6)} km/h</li>
                  <li>Elevation: {p.elevation}</li>
                  <li>Bearing: {p.bearing}</li>
                  <li>Accuracy: {p.accuracy}</li>
                </ul>
              </Popup>
            </CircleMarker>
          )
        })}
      </MapContainer>
    );
  }
}
