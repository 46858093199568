import { connect } from 'react-redux';
import qs from 'query-string';
import { push } from 'connected-react-router';

import fetchDeviceByID from 'actions/fetch-device-by-id';
import fetchPointsByUserDevice from 'actions/fetch-points-by-user-device';
import clearPointsByUserDevice from 'actions/clear-points-by-user-device';
import fetchBoundsOfPointsByUserDevice from 'actions/fetch-bounds-of-points-by-user-device';
import clearBoundsOfPointsByUserDevice from 'actions/clear-bounds-of-points-by-user-device';
import fetchStatisticsOfPointsByUserDevice from 'actions/fetch-statistics-of-points-by-user-device';
import clearStatisticsOfPointsByUserDevice from 'actions/clear-statistics-of-points-by-user-device';
import createCollectionDraft from 'actions/create-collection-draft';
import removeCollectionDraft from 'actions/remove-collection-draft';
import createCollection from 'actions/create-collection';
import setFilter from 'actions/set-filter';
import setCurrentPosition from 'actions/set-current-position';
import logout from 'actions/logout';

import RootView from 'components/root-view';

function mapStateToProps(state, ownProps) {
  const queryParams = qs.parse(ownProps.location.search);
  const deviceID = Number(queryParams.device);
  const start = queryParams.start;
  const end = queryParams.end;

  return {
    session: state.session,
    user: state.user,
    devices: state.devices,
    deviceID,
    points: state.points,
    collections: state.collections,
    start,
    end
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      push: (value) => dispatch(push(value)),

      fetchDeviceByID: fetchDeviceByID(dispatch),
      fetchPointsByUserDevice: fetchPointsByUserDevice(dispatch),
      clearPointsByUserDevice: clearPointsByUserDevice(dispatch),
      fetchBoundsOfPointsByUserDevice: fetchBoundsOfPointsByUserDevice(dispatch),
      clearBoundsOfPointsByUserDevice: clearBoundsOfPointsByUserDevice(dispatch),
      fetchStatisticsOfPointsByUserDevice: fetchStatisticsOfPointsByUserDevice(dispatch),
      clearStatisticsOfPointsByUserDevice: clearStatisticsOfPointsByUserDevice(dispatch),
      createCollectionDraft: createCollectionDraft(dispatch),
      removeCollectionDraft: removeCollectionDraft(dispatch),
      createCollection: createCollection(dispatch),
      setFilter: setFilter(dispatch),
      setCurrentPosition: setCurrentPosition(dispatch),
      logout: logout(dispatch),
      redirectToRoot: () => dispatch(push('/'))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RootView);
