import React, { Component } from 'react';

import CreateDevice from 'components/devices/create-device';
import DeviceList from 'components/devices/device-list';

export default class DevicesView extends Component {
  constructor(props) {
    super(props);

    const { session, actions } = this.props;

    // TODO add better way to control TTL on state so we don't load
    // all devices every time we mount this component
    actions.fetchDevicesByUser(session.data.userID, session.data.token)

    this.state = {
      showCreateDeviceForm: false
    };

    this.toggleCreateDeviceForm = this.toggleCreateDeviceForm.bind(this);
  }

  toggleCreateDeviceForm() {
    this.setState(state => {
      return { showCreateDeviceForm: !state.showCreateDeviceForm }
    })
  }

  render() {
    const { session, devices, actions } = this.props;
    const { showCreateDeviceForm } = this.state;

    const { createDevice } = actions;

    return (
      <div className='devices-container container'>
        <div className='row is-horizontal-align'>
          <div className='col-12'>
            <div className='card'>
              <div className='row'>
                <div className='col is-left'>
                  <h4>Devices</h4>
                </div>
                {!showCreateDeviceForm &&
                  <div className='col is-right'>
                    <button
                      className='button dark'
                      onClick={this.toggleCreateDeviceForm}>
                      CREATE DEVICE
                    </button>
                  </div>
                }
              </div>

              {showCreateDeviceForm ? (
                <CreateDevice
                  session={session}
                  devices={devices}
                  actions={{ createDevice, onDecision: this.toggleCreateDeviceForm }} />
              ) : (
                <DeviceList devices={devices} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
};
