import initialState from 'shared/initial-state';
import actionTypes from 'actions/action-types';

const sessionReducer = (state = initialState.session, action) => {
  switch (action.type) {
  case actionTypes.LOGIN_USER:
    return Object.assign({}, state, { loading: true });
  case actionTypes.LOGIN_USER_SUCCESS:
    return Object.assign({}, state, {
      loading: false,
      lastUpdated: action.receivedAt,
      data: action.data
    });
  case actionTypes.LOGIN_USER_FAILURE:
    return Object.assign({}, state, { loading: false, data: initialState.session});

  case actionTypes.LOGOUT_USER_SUCCESS:
    return Object.assign({}, state, { data: initialState.session});

  case actionTypes.SET_FILTER_SUCCESS:
    return Object.assign({}, state, { filter: action.filter });

  case actionTypes.SET_CURRENT_POSITION_SUCCESS:
    return Object.assign({}, state, { currentPosition: action.currentPosition });

  default:
    return state
  };
};

export default sessionReducer;
