import actionTypes from 'actions/action-types';

export default function clearPointsByUserDevice(dispatch) {
  return function(deviceID) {
    return dispatch({
      type: actionTypes.CLEAR_POINTS_BY_USER_DEVICE_SUCCESS,
      data: { deviceID }
    });
  };
};
